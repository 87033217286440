@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
}

:root {
    /* Colors */
    /*--main-color: #0C0D15;*/
    --main-color: #040404;
    /*--bottom-shadow-color: #1C1A2B;*/
    --bottom-shadow-color: #0E0E0E;
    /*--shadow-color: #180D1B;*/
    --shadow-color: #000;
    --song-info: #000;

    /* Copyrights colors */
    --cr-shadow-color: #000;
    --cr-background-color: #10101B;
    --cr-title-color: #fff;
    --cr-text-color: #fff;

    --info-title-color: #F3F3F3;
    --info-paragraph-color: #BABDB6;

    /* Audio and media info colors */
    /*--audio-thumbnail: url('assets/images/audio.png');*/
    --audio-thumbnail: '';
    --media-info-title: whitesmoke;
    --media-info-content: whitesmoke;

    /* Fonts */
    --main-font: 'monospace', sans-serif;
    --audio-info-font: 'Noto Sans', sans-serif;

    /* Images */
    --main-image: url('assets/images/me.png');
    --main-image-alignment: 50%;
    --main-image-background-color: #10101B;
    --main-image-text-if-no-image: rgba(0, 0, 0, 0);

    /* Shadow on the footer of page */
    --shadow-size: 6em;

    /* Default font size
      Adaptive design by using relative `vw` and `em` values
    */
    font-size: calc(0.5em + 1vw);
}
