a a:hover {
    text-decoration: none;
}

body {
    background-color: var(--main-color);
}

div.content {
    display: block;
}

div.bottomShadow {
    opacity: 0;
    z-index: -1;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20%;
    background-color: var(--bottom-shadow-color);
    box-shadow: 0 0 var(--shadow-size) var(--shadow-size) var(--bottom-shadow-color);
}

div.info {
    opacity: 0;
    margin: 0.7em;
    width: 26em;
    font-family: var(--main-font);
}

div.info > h1 {
    color: var(--info-title-color, #fff);
}

div.info > p {
    color: var(--info-paragraph-color, #fff);
}

div.media {
    opacity: 0;
    position: absolute;
    right: 1em;
    bottom: 1em;
    display: flex;
}

div.mediaInfo {
    margin: auto 10px auto auto;
    line-height: 1;
    text-align: right;
    color: var(--song-info);
    font-family: var(--audio-info-font);

    opacity: 0;
}

div.mediaInfo > h3, div.mediaInfo h3 > a {
    color: var(--media-info-title);
    font-size: 0.95em;
    margin: 0;
}

div.mediaInfo > p {
    color: var(--media-info-content);
    margin: 0;
    font-size: 0.8em;
}

div.media div.thumbnail:hover {
    filter: brightness(0.5);
}

div.media div.thumbnail {
    transition: filter 0.2s;

    display: block;

    background: var(--audio-thumbnail) center center no-repeat #fff;
    background-size: cover;

    background-position: center;
    background-repeat: round;

    border-radius: 0.5em;
    cursor: pointer;
    width: 3.4em;
    height: 3.4em;
    float: right;
}

div.media img {
    position: absolute;
    width: 2em;
    height: 2em;
    left: 0;
    bottom: 0;
}

div.audioPlayer img {
    border-radius: 0.5em;
}

h1.title {
    color: white;
    margin: 5px;
    font-size: 1.4em;

    transition: font-size 0.2s ease;
}

h1.title:hover {
    font-size: 1.5em;
}

p.description {
    display: block;
    color: #BABDB6;
    margin: 5px;
    font-size: 0.71em;

    transition: margin 0.2s ease;
}

div.image {
    opacity: 0;
    display: block;
    position: absolute;
    height: 90%;
    width: 75%;
    left: 50%;
    margin-top: 0.5em;
    margin-left: -37.5%;
    box-shadow: 0 0 6px 1px var(--shadow-color);

    filter: brightness(0.8);

    background-image: var(--main-image);
    background-color: var(--main-image-background-color);
    background-position: var(--main-image-alignment);
    background-size: cover;
    border-radius: 0.5em;
}

div.lineBlock {
    display: block;
    position: absolute;
}

div.lineBlock.topRight {
    top: -0.5em;
    right: -0.5em;
}

div.lineBlock.bottomLeft {
    bottom: -0.5em;
    left: -0.5em;
}

div.line {
    display: block;
    position: relative;
    margin: 0.1em;
    height: 1.5em;
    width: 0;
    background-color: #fff;
}

div.line.bottomRight {
    position: absolute;
    right: 0;
}

a.copyrightLink {
    font-size: 12px;
    color: white;
    position: absolute;
    right: 5px;
    bottom: 5px;
}
